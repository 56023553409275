import * as React from 'react';
import { useDashboardContext } from '../Context';
import GadgetBoard from './GadgetBoard';

export default function DashboardContent() {
  const {
    state: { currentDashboard },
  } = useDashboardContext();

  if (!currentDashboard) {
    return null;
  }

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <GadgetBoard />
    </React.Suspense>
  );
}
