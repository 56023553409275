import * as React from 'react';
import Image from 'next/image';
import { useDashboardActionsContext } from '../../Context';
import { generateUUID } from '../../apis';
import { GadgetSpecProps } from '../../types';
import { clsx } from 'clsx';
import dummyThumbnail from './dummy-thumb.png';

export const getGadgetDefaultData = () => {
  return {
    groupNo: 0,
    region: 0,
    sequence: 0,
    option: '{"refresh":-1}',
  };
};

export interface Props {
  dashboardId: number;
  spec: GadgetSpecProps;
  className?: string;
}

export default function GadgetSpecItem({ spec, className }: Props) {
  const context = useDashboardActionsContext();
  const { getGadgetsByColumnSeq, reorderGadgets, getGadgetThumbnail } = context;

  const handleDragStart = (e: React.DragEvent) => {
    if (!e.nativeEvent.dataTransfer) return;
    e.nativeEvent.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        ...spec,
      }),
    );
  };

  const handleGadgetSpecClick = () => {
    const gadgets = getGadgetsByColumnSeq(0);
    if (!gadgets) return;

    const sequence = gadgets.length;
    const gadget = {
      ...getGadgetDefaultData(),
      ...spec,
      sequence,
      region: 0,
      uuid: generateUUID(),
    };
    reorderGadgets(gadget, 0, 0);
  };

  const thumbnail = getGadgetThumbnail(spec.name) || dummyThumbnail;

  return (
    <li
      className={clsx(
        'shrink-0 group flex flex-col items-center gap-y-2 hover:cursor-pointer',
        className,
      )}
      draggable
      onDragStart={handleDragStart}
      onClick={() => void handleGadgetSpecClick()}
      aria-hidden="true"
    >
      <Image
        src={thumbnail}
        className="h-[100px] w-[auto] group-hover:border-2 group-hover:border-[#25cad6] group-hover:border-solid group-hover:rounded group-hover:shadow-lg group-hover:scale-95 group-hover:transition-transform"
        alt={''}
        width={'100'}
        height={'100'}
      />
      <p className="text-center">{spec.displayName}</p>
    </li>
  );
}
