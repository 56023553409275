import { PlusIcon } from '@daouoffice/ui/lib/icons/dop/24/Plus';
import { useTranslation } from '../../../lib/i18n/client/useTranslation';
import { useDashboardContext } from '../Context';
import HeaderContainer from './Container';
import Tab from './Tab';
import { DashboardProps } from '../types';

export function TabContainer() {
  const { t } = useTranslation();
  const context = useDashboardContext();
  const { gadgets, dashboards, currentDashboard } = context.state;
  const {
    setCurrentDashboard,
    setEditMode,
    setGadgets,
    setOriginGadgets,
    setPrevDashboard,
  } = context.actions;
  const isDashboardAddable = dashboards.length < 5;

  const snapshot = () => {
    setOriginGadgets(gadgets);
    setPrevDashboard(currentDashboard);
  };

  const handleEditDashboardClick = () => {
    snapshot();
    setEditMode(true);
  };

  const handleCreateDashboardClick = () => {
    const tempDashboardData = {
      dashboardId: -1,
      name: t('dashboard.privateDashboard'),
      layout: 1,
      activated: true,
      updatable: true,
      deletable: true,
    };
    snapshot();
    setGadgets([]);
    setCurrentDashboard(tempDashboardData);
    setEditMode(true);
  };

  return (
    <HeaderContainer>
      <ul className="h-full flex gap-x-[20px]">
        {dashboards.map((dashboard: DashboardProps) => (
          <Tab
            key={`dashboard-${dashboard.dashboardId}`}
            name={dashboard.name}
            onClick={handleEditDashboardClick}
            dashboard={dashboard}
          />
        ))}

        {isDashboardAddable && (
          <li className="flex items-center">
            <button onClick={() => void handleCreateDashboardClick()}>
              <PlusIcon size={20} />
            </button>
          </li>
        )}
      </ul>
    </HeaderContainer>
  );
}

export default TabContainer;
