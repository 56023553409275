import { Languages } from '@daouoffice/ui/lib/gadget/Gadget/locale';
import { TextProps } from './type';

export const GadgetTexts: Languages<TextProps> = {
  ko: { gadgetTitle: '' },
  en: { gadgetTitle: '' },
  ja: { gadgetTitle: '' },
  zh_CN: { gadgetTitle: '' },
  zh_TW: { gadgetTitle: '' },
  vi: { gadgetTitle: '' },
};
