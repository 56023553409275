import Header from './Header';
import { Root, type Props as RootProps } from './Root';
import Content from './Content';

export * from './types';

export default function Dashboard(props: RootProps) {
  return (
    <Root {...props}>
      <Header />
      <Content />
    </Root>
  );
}
